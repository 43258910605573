import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Contact } from "../components/contact-us"
import SEO from "../components/seo"

const MentionsLegales = ({ data }) => (
  <Layout>
    <SEO title="Mentions Légales" />

<div style={{
        alignItems: 'stretch',
        WebkitBoxAlign: 'stretch',
        margin: 'auto',
        padding: '30px',
     }}>

<h1>Mentions Légales</h1>

<p>Conformément aux dispositions de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, nous vous informons que :</p>

<p>Le site internet saadify.fr est édité par :<br/>
<br/>
Saâd DIF<br/>
Numéro TVA intracommunautaire : FR65 789 445 608<br/>
Numéro SIREN : RCS Bobigny 789 445 608<br/>
Siège social : 2 bis place du château d'eau - 93120 La Courneuve<br/>
Téléphone : +33 (0)6 65 36 13 07<br/>
Email : contact@saadify.fr<br/>
<br/>
Le directeur de la publication du site web saadify.fr est Saâd DIF.<br/>
</p>

<p><h2>Hébergement</h2>
Le site saadify.fr est hébergé par la société Amazon Web Services:<br/>
<br/>
Amazon Web Services LLC<br/>
P.O. Box 81226<br/>
Seattle, WA 98108-1226<br/>
http://aws.amazon.com<br/>
</p>

<p>Lien vers les <Link to="/conditions-generales-vente">Conditions Générales de Vente</Link></p>
<p>Lien vers la <Link to="/cookies">Politique d'utilisation des cookies</Link></p>




</div>



    <Contact
      contactButton = {[
        "/contact",
        "Contact"
      ]}
    />
  </Layout>
)

export default MentionsLegales

// export const pageQuery = graphql`
//   query {
//     hero: allImageSharp(filter: { original: { src: { regex: "/ecommerce-3562005_1280/" } } }) {
//       edges {
//         node {
//           id
//           fluid(quality: 100) {
//             ...GatsbyImageSharpFluid_withWebp
//           }
//         }
//       }
//     }
//   }
// `;
